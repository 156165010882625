<template>
  <!--<div class="icons" v-show="visiable1" @click="handleScrollTop">-->
  <div class="icons" @click="handleScrollTop">
    <el-image style="top: 50%; left: 50%;transform: translate(-50%,-50%);" :src="require('../../../assets/banner/returntop.png')" />
  </div>
  <iframe class="openIframe" src="https://affim.baidu.com/unique_56632585/chat?siteId=20701288&userId=56632585&siteToken=7c2a048242f073080bb032c1f16c29ea" width="770px" height="670px"  v-if="showframe">
  </iframe>
  <div class="closeIframe" @click="closeIframe" v-show="showframe">
    <img src="./image/close1.png" alt="">
  </div>
  <div class="totalbck" v-if="checkup" v-show="visiable">
    <div class="icons1"  @click="online">
      <el-image
        class="exit1"
        :src="require('../../../assets/banner/service.png')"
      />
      <el-image
        class="exit2"
        :src="require('../../../assets/banner/servicecopy.png')"
      />
    </div>
    <div class="icons2">
      <el-image
        class="exit3"
        :src="require('../../../assets/banner/phone.png')"
      />
      <el-image
        @mouseenter="handleEnter1"
        @mouseleave="handleLeave1"
        class="exit4"
        :src="require('../../../assets/banner/phonecopy.png')"
      >
      </el-image>
    </div>
    <div class="icons3" @click="$router.push('/hard')">
      <el-image
        class="exit5"
        :src="require('../../../assets/banner/message1.png')"
      />
      <el-image
        class="exit6"
        :src="require('../../../assets/banner/messagecopy.png')"
      />
    </div>
    <div class="icons4" @click="handelClickPush">
      <el-image
        class="exit7"
        :src="require('../../../assets/banner/society.png')"
      />
      <el-image
        class="exit8"
        :src="require('../../../assets/banner/societycopy.png')"
      />
    </div>
    <div class="icons5">
      <el-image
        class="exit9"
        :src="require('../../../assets/banner/wechat.png')"
      />
      <el-image
        @mouseenter="handleEnter2"
        @mouseleave="handleLeave2"
        class="exit10"
        :src="require('../../../assets/banner/wechatcopy.png')"
      />
    </div>
  </div>
  <div class="totalbck" v-else v-show="visiable">
    <!--<div class="iconsfont1" @click="$router.push('/searchgoodinfo')">
      <span>快速搜索</span>
    </div>-->
	  <div
			  class="iconsfont2"
			  @mouseenter="handleEnter2"
			  @mouseleave="handleLeave2"
	  >
		  <span>配置咨询</span>
	  </div>
    <div class="iconsfont4" @click="online">
      <span v-track="{event:'在线客服',page:'在线客服'}">在线客服</span>
    </div>
    <!--<div
      class="iconsfont3"
      @mouseenter="handleEnter1"
      @mouseleave="handleLeave1"
    >
      <span>咨询热线</span>
    </div>-->
    <!-- <div class="iconsfont3" @click="router.push('/hard')">
      <span>业务咨询</span>
    </div> -->
    <div class="iconsfont6" @click="handelClickPush">
      <span>售后支持</span>
    </div>
    <div
      class="iconsfont5"
      @mouseenter="handleEnter3"
      @mouseleave="handleLeave3"
    >
      <span><span>小</span><br/>程序</span>
    </div>
  </div>
  <div class="backtwo" v-if="backtwo">
    <div class="backtwofont">
      联系方式：<br />
      <p style="color: #333333">400-7786-686</p>
      <br />
      工作时间：<br />
      <p style="color: #333333">周一 ~ 周六</p>
      <p style="color: #333333">08: 30 ~ 18: 00</p>
    </div>
  </div>
  <div class="backsix" v-if="backfive">
    <el-image
      class="qrcode-image"
      :src="require('../../../assets/banner/code1.jpg')"
    ></el-image>
    <div style="position: relative;bottom: 4px;">
      <p style="text-align: center; align-items: center;">扫码加客户经理微信</p>
    </div>
  </div>
  <div class="backfive" v-if="backsix">
    <el-image
    class="qrcode-image"
    :src="require('../../../assets/banner/code2.png')"
    ></el-image>
    <div style="position: relative;bottom: 4px;">
      <p style="text-align: center; align-items: center;">宁美商用小程序</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import emitter from "@/utils/eventBus";
import { useRouter } from "vue-router";
import {baiduClue} from "@/api/shop";
const router = useRouter();
const scrollTop = ref(""); // 距顶部距离
const visiable = ref(true);
const visiable1 = ref(false);
let done = false; // 是否停止帧函数
const backtwo = ref(false);
const backfive = ref(false);
const checkup = ref(false);
const showframe = ref(false);
// const timer = setInterval(() => {
//   checkup.value = !checkup.value;
// }, 3000);
const handleEnter1 = (res) => {
  backtwo.value = true;
};
const closeIframe = (res) => {
  showframe.value = false
};
const handleLeave1 = (res) => {
  backtwo.value = false;
};
const handleEnter2 = (res) => {
  backfive.value = true;
};
const handleLeave2 = (res) => {
  backfive.value = false;
};
const backsix = ref(false)
const handleEnter3 = (res) => {
  backsix.value = true;
};
const handleLeave3 = (res) => {
  backsix.value = false;
};
// 点击在线客服
const online = () => {
	// 百度埋点
	if(sessionStorage.getItem("bd_vid")){
		const buriedData = [
			{
				logidUrl: window.location.href,
				newType: 17,
			}
		]
		baiduClue(buriedData)
	}
	//window.open("https://affim.baidu.com/unique_56632585/chat?siteId=20701288&userId=56632585&siteToken=7c2a048242f073080bb032c1f16c29ea")
	// window.open("https://cschat.antcloud.com.cn/index.htm?tntInstId=nGx_rHip&scene=SCE01268584")
  showframe.value = true
};
emitter.on("onlineClient", () => {
  showframe.value = true
	online()
});
const handelClickPush = () => {
  router.push({
    path: "/support",
  });
};
// 检测距离
/*function handleScroll() {
  scrollTop.value =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop;
  scrollTop.value > 500 ? (visiable1.value = true) : (visiable1.value = false);
}*/
// 动画帧函数
function step() {
  scrollTop.value <= 0 ? (done = true) : (done = false);
  window.scrollTo({
    // top: (scrollTop.value -= 100), // 添加了动画
    top: (scrollTop.value -= 2000),  // 没有动画
  });
  if (!done) {
    window.requestAnimationFrame(step);
  }
}
// 返回顶部
function handleScrollTop() {
  // 下一次重绘之前更新动画帧所调用的函数 60次1秒
  window.requestAnimationFrame(step);
}
// 设置监听与卸载
/*onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});
onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});*/
</script>

<style lang="less" scoped>
.openIframe{
  position: fixed;
  right: 6.5rem;
  bottom: 2rem;
  border: none;
  box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.2);
  border-radius: 20px;
  z-index: 999;
}
.closeIframe{
  cursor: pointer;
  position: fixed;
  right: 8rem;
  bottom: 645px;
  z-index: 999;
}
.totalbck {
  cursor: pointer;
  position: fixed;
  //left: 113.6rem;
  right: 1.5rem;
  bottom: 4rem;
  width: 60px;
  height: 240px;
  background: #ffffff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  z-index: 999;
}
.icons {
  position: fixed;
  //left: 113.9rem;
  right: 2rem;
  bottom: 21rem;
  width: 50px;
  height: 50px;
  background: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  z-index: 999;
  cursor: pointer;
}
.iconsfont1 {
  position: fixed;
  //left: 114.6rem;
  right: 2.4rem;
  bottom: 21.9rem;
  width: 31px;
  height: 28px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}

.iconsfont1:hover {
  color: #fffefe;
  background-color: #ce1200;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  position: fixed;
  //left: 114rem;
  right: 1.88rem;
  bottom: 21.2rem;
  padding: 10px;
}

.iconsfont2 {
  position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 28px;
  width: 31px;
  height: 28px;
	text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}
.iconsfont2:hover {
  color: #fffefe;
  background-color: #ce1200;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  top: 17px;
  padding: 10px;
}
.iconsfont3 {
  position: fixed;
  //left: 114.6rem;
  right: 2.4rem;
  bottom: 15.1rem;
  width: 31px;
  height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}
.iconsfont3:hover {
  color: #fffefe;
  background-color: #ce1200;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  position: fixed;
  //left: 114rem;
  right: 1.88rem;
  bottom: 14.6rem;
  padding: 10px;
}
.iconsfont4 {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 87px;
  width: 31px;
  height: 32px;
	text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}
.iconsfont4:hover {
  color: #fffefe;
  background-color: #ce1200;
  border-radius: 30px;
  width: 50px;
  height: 50px;
	top: 78px;
  padding: 10px;
}
.iconsfont5 {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 140px;
  width: 31px;
  height: 32px;
	text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}
.iconsfont5:hover {
  color: #fffefe;
  background-color: #ce1200;
  border-radius: 30px;
  width: 50px;
  height: 50px;
	top: 129px;
  padding: 10px;
}
.iconsfont6{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 190px;
  width: 31px;
  height: 32px;
	text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}
.iconsfont6:hover {
  color: #fffefe;
  background-color: #ce1200;
  border-radius: 30px;
  width: 50px;
  height: 50px;
	top: 178px;
  padding: 10px;
}
.icons1 {
  position: fixed;
  right: 1.7rem;
  bottom: 18rem;
  margin: 0.9rem;
  background-color: #ffffff;
  border-radius: 25px;
}
.backtwo {
  width: 160px;
  height: 140px;
  background: #ffffff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  right: 6rem;
  bottom: 14rem;
}
.backfive {
  width: 200px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  //left: 100rem;
  right: 6rem;
  bottom: 7.8rem;
}
.backsix{
  width: 200px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  //left: 100rem;
  right: 6rem;
  bottom: 4.9rem;
}

.qrcode-image{
	margin: 10px 0 0 18px;
	width: 162px;
	height: 162px;
}
.backtwofont {
  padding: 15px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce1200;
  z-index: 20;
}

.exit4 .two {
  width: 160px;
  height: 125px;
  background: #ffffff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
}
.icons1 .exit1 {
  display: inline-block;
}
.icons1 .exit2 {
  display: none;
}
.icons1:hover .exit1 {
  display: none;
}
.icons1:hover .exit2 {
  display: inline-block;
  position: fixed;
  right: 1.8rem;
  bottom: 18.5rem;
}

.icons2 {
  position: fixed;
  right: 1.7rem;
  bottom: 14rem;
  padding: 0.9rem;
  background-color: #ffffff;
  border-radius: 25px;
}
.icons2 .exit3 {
  display: inline-block;
}
.icons2 .exit4 {
  display: none;
}
.icons2:hover .exit3 {
  display: none;
}
.icons2:hover .exit4 {
  display: inline-block;
  position: fixed;
  right: 1.8rem;
  bottom: 14.5rem;
}

.icons3 {
  position: fixed;
  right: 1.7rem;
  bottom: 10.3rem;
  padding: 0.9rem;
  background-color: #ffffff;
  border-radius: 25px;
}
.icons3 .exit5 {
  display: inline-block;
}
.icons3 .exit6 {
  display: none;
}
.icons3:hover .exit5 {
  display: none;
}
.icons3:hover .exit6 {
  display: inline-block;
  position: fixed;
  right: 1.8rem;
  bottom: 10.8rem;
}

.icons4 {
  position: fixed;
  right: 1.7rem;
  bottom: 6.3rem;
  padding: 0.9rem;
  background-color: #ffffff;
  border-radius: 25px;
}
.icons4 .exit7 {
  display: inline-block;
}
.icons4 .exit8 {
  display: none;
}
.icons4:hover .exit7 {
  display: none;
}
.icons4:hover .exit8 {
  display: inline-block;
  position: fixed;
  right: 1.8rem;
  bottom: 6.8rem;
}

.icons5 {
  position: fixed;
  right: 1.7rem;
  bottom: 2.6rem;
  padding: 0.9rem;
  background-color: #ffffff;
  border-radius: 25px;
}
.icons5 .exit9 {
  display: inline-block;
}
.icons5 .exit10 {
  display: none;
}
.icons5:hover .exit9 {
  display: none;
}
.icons5:hover .exit10 {
  display: inline-block;
  position: fixed;
  right: 1.9rem;
  bottom: 3.1rem; 
}

.icons > .icon {
  color: #1abc9c;
}
.icons > .text {
  display: none;
  font: bold 100% Consolas, Monaco, monospace;
  margin: 0;
  text-align: center;
  color: #2980b9;
}
.icons:hover > .text {
  display: block;
}
.icons:hover > .icon {
  display: none;
}
</style>
